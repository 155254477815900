import React, { useContext, useRef, useState } from "react";
import { UserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";

export default function SignUpModal() {
  const { modalState, toggleModals, signIn } = useContext(UserContext);
  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const inputs = useRef([]);
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };
  const formRef = useRef();

  const handleForm = async (e) => {
    e.preventDefault();
    console.log(inputs)
    try {
      const cred = await signIn(
        inputs.current[0].value,
        inputs.current[1].value
      );
      setValidation("");
      console.log(cred);
      toggleModals("close");
      navigate("/mon-compte");
    } catch {
      setValidation("Wopsy, email and/or password incorrect");
    }
  };

  const closeModal = () => {
    setValidation("");
    toggleModals("close");
  };

  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains("bg-black")) {
      closeModal();
    }
  };

  const next = () => {
    closeModal()
    toggleModals("signUp")
  }
 const upnext =() => {
  closeModal()
  toggleModals("Resetpassword")
 }
  return (
    <>
      {modalState.signInModal && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center font-montserrat bg-black bg-opacity-50 z-10"
          onClick={handleBackgroundClick}
        >
          <div className="bg-whitesmoke rounded-xl overflow-hidden shadow-lg w-[500px]">
            <div className="bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] flex text-light justify-center mb-12">
              <h5 className="text-xl font-medium text-white">
                Se connecter
              </h5>
              <span style={{ cursor: 'pointer' }} onClick={closeModal}>
                <i className="fa-solid fa-xmark fa-1x"></i>
              </span>
            </div>
            <form ref={formRef} onSubmit={handleForm}>
              <div className="text-light my-4 px-7">
                <input
                  ref={addInputs}
                  name="email"
                  required
                  type="email"
                  className="w-full text-[17px] rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat"
                  id="signInEmail"
                  placeholder="Adresse Email"
                />
              </div>
              <div className="mb-3 px-7">
                <input
                  ref={addInputs}
                  name="pwd"
                  required
                  type="password"
                  className="w-full text-[17px] rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat"
                  id="signInPwd"
                  placeholder="Mot de passe"
                />
                <p className="text-center text-[#b91c1c] m-4">{validation}</p>
              </div>
			  <div className='flex pb-4'>
					<button className="cursor-pointer mx-auto rounded-md font-montserrat font-medium px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-base text-white mt-6">
						Connexion
					</button>
				</div>
              <div className="flex flex-col text-center mt-12">
                <b className="text-slate-500 font-normal m-1">Vous n'avez pas de compte ?</b>
                <b className="cursor-pointer font-semibold mt-1 mb-5" onClick={next}>S'inscrire</b>
                <b className="text-slate-500 font-normal m-1">Vous avez oublier votre mot de passe ?</b>
                <b className="cursor-pointer font-semibold mb-5" onClick={upnext}>Mot de Passe oublié </b>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
