import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, update, push, get, onValue, off } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_live_51Q0mhPGKJkbnI5UCqiu4fdhRxeRYnGPpmCNx5wTcVFMLNwlQDQIXcG4z1avlNH7j7QZ9wLsdJIpR8Q5AFNrspotS00NHUvZFfO'); // Clé publique Stripe

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATA_BASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default function TokenPurchaseForm({ onClose }) {
  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const [amount, setAmount] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const auth = getAuth();
  const [tokenTransactions, setTokenTransactions] = useState([]);
  const inputs = useRef([]);
  const transactionFeeRate = 0.03; // 3% frais de transaction

  const adminUserId = "eoIP154cJmT8xHD9syk7Njg5cj43";  // UID du compte administrateur

  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };

  const formRef = useRef();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, navigate]);

  useEffect(() => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      const transactionRef = ref(database, `transactions/${userId}`);

      const unsubscribeTransactions = onValue(transactionRef, (snapshot) => {
        const transactions = snapshot.val();
        if (transactions) {
          setTokenTransactions(Object.values(transactions));
        }
      });

      return () => {
        off(transactionRef, unsubscribeTransactions);
      };
    }
  }, [auth.currentUser]);

  const updateAdminBalance = async (feeAmount, userId) => {
    const adminTokenBalanceRef = ref(database, `users/${adminUserId}/tokenBalance`);
    const adminTransactionRef = push(ref(database, `transactions/${adminUserId}`));
    const adminSnapshot = await get(adminTokenBalanceRef);
    let adminBalance = adminSnapshot.val()?.balance || 0;

    adminBalance += feeAmount;

    await update(adminTokenBalanceRef, { balance: adminBalance });

    const adminTransaction = {
      amount: feeAmount,
      timestamp: Date.now(),
      EntrepriseName: "Frais",
      sourceUserId: userId,
    };

    await update(adminTransactionRef, adminTransaction);
  };

  const updateUserTokenBalance = async (user, netAmount, transactionFee) => {
    const userId = user.uid;
    const userTokenBalanceRef = ref(database, `users/${userId}/tokenBalance`);
    const transactionRef = push(ref(database, `transactions/${userId}`));
    const feeRef = push(ref(database, `fees`));

    const newTransaction = {
      amount: netAmount,
      timestamp: Date.now(),
      EntrepriseName: "Dépôt",
      lastPrice: netAmount,
      transactionFee: transactionFee,
    };

    const userSnapshot = await get(userTokenBalanceRef);
    const userBalance = userSnapshot.val()?.balance || 0;
    await update(userTokenBalanceRef, { balance: userBalance + netAmount });
    await update(transactionRef, newTransaction);

    const feeData = {
      userId: userId,
      feeAmount: transactionFee,
      timestamp: Date.now(),
    };
    await update(feeRef, feeData);

    await updateAdminBalance(transactionFee, userId);
  };

  const calculateFees = (inputAmount) => {
    const parsedAmount = parseFloat(inputAmount) || 0;
    const fee = parsedAmount * transactionFeeRate;
    const net = parsedAmount - fee;
    setTransactionFee(fee);
    setNetAmount(net);
  };

  const handleCheckout = async (e) => {
    e.preventDefault();

    const amountInput = inputs.current[0].value;

    try {
      if (amountInput === "") {
        setValidation("Veuillez remplir tous les champs.");
        return;
      }

      const tokenMax = 100000;
      const tokenAmount = parseFloat(amountInput);
      const token1AmountRequired = 1;

      if (tokenAmount < token1AmountRequired) {
        setValidation("Vous devez déposer au moins 10 euros.");
        return;
      }

      if (tokenAmount > tokenMax) {
        setValidation("Le maximum de dépôt est de 100 000 euros.");
        return;
      }

      const stripe = await stripePromise;
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: tokenAmount }),
      });

      const session = await response.json();

      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        setValidation(result.error.message);
      }
    } catch (error) {
      console.error("Erreur lors de la redirection vers Stripe:", error.message);
      setValidation("Une erreur s'est produite lors de la redirection vers le paiement.");
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    calculateFees(e.target.value);
  };

  useEffect(() => {
    if (auth.currentUser) {
      const urlParams = new URLSearchParams(window.location.search);
      const success = urlParams.get('success');

      if (success) {
        updateUserTokenBalance(auth.currentUser, netAmount, transactionFee);
      }
    }
  }, [auth.currentUser, netAmount, transactionFee]);

  const closeModal = () => {
    setValidation("");
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <>
      <div
        onClick={closeModal}
        className="fixed top-0 left-0 w-full h-full flex items-center justify-center font-montserrat bg-black bg-opacity-50 z-10"
      >
        <div
          className="bg-whitesmoke rounded-xl overflow-hidden shadow-lg w-[500px]"
          style={{ zIndex: "1050", maxWidth: "600px", width: "90%" }}
          onClick={(e) => { e.stopPropagation(); }}
        >
          <div className="bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] flex text-light justify-center">
            <h5 className="text-xl font-medium text-white">Déposer des euros</h5>
            <span style={{ cursor: "pointer" }} onClick={closeModal}>
              <i className="fa-solid fa-xmark fa-1x"></i>
            </span>
          </div>
          <form ref={formRef} onSubmit={handleCheckout} className="p-6">
            <div className="mb-4">
              <label htmlFor="amount" className="block font-montserrat mb-1">
                Montant :
              </label>
              <input
                type="text"
                className="form-control w-full text-[17px] rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat"
                pattern="[0-9]+([,\.][0-9]+)?"
                placeholder="Entrez le montant du dépôt"
                min={1}
                id="amount"
                ref={addInputs}
                value={amount}
                onChange={handleAmountChange}
              />
            </div>

            {amount >= 1 && (
              <div className="mb-4">
                <p>Frais de transaction (3%) : {transactionFee.toFixed(2)} €</p>
                <p>Montant net crédité : {netAmount.toFixed(2)} €</p>
              </div>
            )}

            <div className="text-center">
              <button
                type="submit"
                className="cursor-pointer mx-auto rounded-md font-montserrat font-medium px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-base text-white"
              >
                Procéder au paiement
              </button>
            </div>

            {validation && (
              <div className="alert alert-danger mt-3">{validation}</div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
