import CbdCompany from '../Assets/Images/CBD.png';
import TeamImage  from '../Assets/Images/imgMYRE.jpg';


function Actualite(){
    return(
        <>
        <b className="flex justify-center text-center text-3xl lg:text-4xl mb-12" style={{
      background: 'linear-gradient(to right, #A9544F, #8C3294)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      }}>Notre Actualité </b>
	  <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 mb-20'>
		<div data-aos="fade-right">
			<img
			className="rounded-md w-full"
			alt="Actu"
			src={TeamImage}
			/>
		</div>
		<div data-aos="fade-left">
			<h3 className="text-2xl mt-0">La Première Immersion</h3>
            <p className="mb-8" >L'événement "Immersion", qui s'est tenu du 21 au 23 juin 2024 au Haut Loft à Toulouse, a marqué une première inoubliable pour les participants. Conçu comme une expérience immersive unique, cet événement a réuni des entrepreneurs, des créateurs et des passionnés de technologie pour trois jours d'innovation, de collaboration et d'inspiration. Dans le cadre prestigieux du Haut Loft, les participants ont pu assister à des conférences, des ateliers interactifs et des démonstrations exclusives, le tout dans une ambiance propice aux échanges et à la création de nouvelles synergies. "Immersion" a non seulement offert un aperçu des tendances de demain, mais a également renforcé la communauté entrepreneuriale de Toulouse.</p>
            <button className="cursor-pointer rounded-md font-montserrat font-medium text-base px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-white">
            Lire plus
            </button>
		</div>
	</div>
	<div className='grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 mb-24'>
		<div data-aos="fade-left" className='lg:order-last'>
			<img
			className="rounded-md w-full"
			alt="Actu"
			src={CbdCompany}
			/>
		</div>
		<div data-aos="fade-right">
			<h3 className="text-2xl mt-0">Le Gestminter Indépendant</h3>
            <p className="mb-8" >"Gestminter indépendant," est conçue pour les entreprises qui souhaitent tirer parti des services offerts par l'écosystème Backstorm sans pour autant être cotées sur la place de marché privée de l'écosystème. Contrairement aux autres listing où Backstorm détient une participation directe dans les entreprises listées, les entreprises optant pour la licence Gestminter indépendant conservent une totale autonomie et propriété de leur capital.</p>
            <button className="cursor-pointer rounded-md font-montserrat font-medium text-base px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-white">
            Lire plus
            </button>
		</div>
		
	  </div>
        </>
        );
}
export default Actualite;

