import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, update, push, onValue, off, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATA_BASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default function NewTokenSaleForm4({ onClose, newTotalBalance, lastPrice, K, prix, quantiteBnf, variation }) {

  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const auth = getAuth();
  const user = auth.currentUser;
  const inputs = useRef([]);
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };
  const formRef = useRef();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, navigate]);

  const updateUserTokenBalance4 = (user, amount, nouveauPrix) => {
    const userId = user.uid;

    const userTokenBalance4Ref = ref(database, `users/${userId}/tokenBalance4`);
    const transactionRef = push(ref(database, `transactions/${userId}`));
    const allInformationsRef = ref(database, `globalInformation`);

    get(allInformationsRef).then((snapshot) => {
      const { informationArray } = snapshot.val();
      const lastPrice = informationArray[3].lastPrice;
      const price = informationArray[3].price;
      const variation = informationArray[3].variation;

      const updatedInfo = {
        ...informationArray[3],
        lastPrice: nouveauPrix,
        quantiteBnf: Number(informationArray[3].quantiteBnf) + Number(amount),
        variation: (((lastPrice - price) / price) * 100),
      };

      informationArray[3] = updatedInfo;

      const newTransaction4 = {
        amount: lastPrice * amount,
        timestamp: Date.now(),
        EntrepriseName: "Prodetection",
      };

      update(userTokenBalance4Ref, {
        balance: lastPrice * amount,
      });
      update(transactionRef, newTransaction4, variation);
      update(allInformationsRef, { informationArray });
    });
  };

  const updateNewTokenBalance4 = (user, amount) => {
    const userId = user.uid;
    const newTokenTransaction4Ref = push(ref(database, `newTokenTransactions4/${userId}`));

    const newTransaction4 = {
      amount: -amount,
      lastPrice: lastPrice,
      timestamp: Date.now(),
      EntrepriseName: "Prodetection",
    };

    update(newTokenTransaction4Ref, newTransaction4);
  };

  const createNewTotalBalance4 = (user) => {
    const userId = user.uid;
    const newTotalBalanceRef = ref(database, `newTotalBalance4/${userId}`);
    const newTokenTransaction4Ref = ref(database, `newTokenTransactions4/${userId}`);

    const updateNewTotalBalance4 = () => {
      onValue(newTokenTransaction4Ref, (snapshot) => {
        const transactions = snapshot.val();
        let newTotalBalance4 = 0;

        if (transactions) {
          Object.values(transactions).forEach((transaction) => {
            newTotalBalance4 += parseFloat(transaction.amount);
          });
        }

        update(newTotalBalanceRef, {
          balance: newTotalBalance4,
        });
      });
    };

    updateNewTotalBalance4();

    return () => {
      off(newTokenTransaction4Ref, updateNewTotalBalance4);
    };
  };

  const calculateTotalBalance = (userId) => {
    const transactionRef = ref(database, `transactions/${userId}`);
    const totalBalanceRef = ref(database, `totalBalance/${userId}`);

    const updateTotalBalance = (snapshot) => {
      const transactions = snapshot.val();
      let totalBalance = 0;

      if (transactions) {
        Object.values(transactions).forEach((transaction) => {
          totalBalance += parseFloat(transaction.amount);
        });
      }

      update(totalBalanceRef, {
        balance: totalBalance,
      });
    };

    onValue(transactionRef, updateTotalBalance);

    return () => {
      off(transactionRef, updateTotalBalance);
    };
  };

  useEffect(() => {
    if (user) {
      calculateTotalBalance(user.uid);
      createNewTotalBalance4(user);
    }
  }, [user]);

  const handleForm = async (e) => {
    e.preventDefault();
    const amount = inputs.current[0].value;
    const nouveauPrix = prix - (quantiteBnf - (quantiteBnf - amount)) / (quantiteBnf * K);

    try {
      if (amount === "") {
        setValidation("Veuillez entrer un montant.");
        return;
      }
      if (amount > newTotalBalance) {
        setValidation("Vous ne disposez pas autant de BnF pour effectuer cette opération");
        return;
      }
      
      const newTokenAmount = amount;

      updateUserTokenBalance4(user, newTokenAmount, nouveauPrix);
      updateNewTokenBalance4(user, newTokenAmount);

      formRef.current.reset();
      setValidation("");

      navigate("/mon-compte");

      onClose();
    } catch {
      setValidation("Une erreur s'est produite lors de la vente des Bnf's.");
    }
  };

  const closeModal = () => {
    setValidation("");
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <>
      <div className="position-fixed top-0 start-0 vw-100 vh-100 d-flex align-items-center justify-content-center">
        <div onClick={closeModal} className="w-100 h-100 bg-dark bg-opacity-75"></div>
        <div
          className="position-absolute top-50 start-50 translate-middle bg-light shadow-lg rounded p-4"
          style={{ zIndex: "1050", maxWidth: "600px", width: "90%" }}
        >
          <h3 className="text-center mb-4">Vendre CART</h3>
          <form ref={formRef} onSubmit={handleForm}>
            <div className="mb-3">
              <label htmlFor="amount" className="form-label">
                Montant :
              </label>
              <input
                ref={addInputs}
                type="text"
                pattern="[0-9]+([,.][0-9]+)?"
                className="form-control"
                id="amount"
                placeholder="Entrez le montant de CAR"
                required
              />
            </div>
            {validation && <p className="text-danger">{validation}</p>}
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary me-2">
                Vendre
              </button>
              <button type="button" onClick={closeModal} className="btn btn-secondary">
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
