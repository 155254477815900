import Hero2 from './Hero2';
import Hero from './Hero';
import Footer from './Footer';
import Youtube from './Youtube';
import Youtube2 from './Youtube2';

const ProductPage = () => {
  
  return (
    <div className="bg-whitesmoke font-montserrat text-[17px] leading-6">
		<div className='container mx-auto'>
			<div className='px-6'>
				<Hero2 />
				<Youtube />
				<Hero/>
				<Youtube2 />
			</div>
		</div>
		<Footer />
	</div>
  );
};


export default ProductPage;
