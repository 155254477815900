import React from "react";

const Youtube = () => {
  return (
    <div className="flex justify-center aspect-video flex-col items-center" data-aos="fade-up">
        <iframe
          src="https://www.youtube.com/embed/zEldsEx-69k"
          title="YouTube video"
          allowFullScreen
          className="h-[250px] sm:h-[700px] w-full rounded-lg"
        ></iframe>
    </div>
  );
};

export default Youtube;
