import discord from "../Assets/Images/discord.png";
// import twitter from "../Assets/Images/Twitter.png";
// import instagram from "../Assets/Images/Instagram.png";
import twitter from "../Assets/Images/twitter.svg";
import instagram from "../Assets/Images/instagram.svg";


function Footer(){
    return(
      <footer className="-mx-6 pt-12 font-montserrat">
      <div className="bg-[#2B2B2B] text-zinc-400 text-sm p-6">
		<div className="container mx-auto">
			<div className="grid grid-cols-1 md:grid-cols-2 px-6">
				<section className="flex items-center justify-start max-md:flex-col">
					<div className="flex max-md:flex-col items-center gap-4 md:gap-x-8">
					<div className="cursor-pointer hover:text-white">Conditions&nbsp;Générales</div>
					<div className="cursor-pointer hover:text-white">Données&nbsp;personnelles</div>
					<div className="cursor-pointer hover:text-white">Aides</div>
					</div>
				</section>
				<div className="flex justify-center md:justify-end gap-4 max-md:mt-8">
					<a href="https://discord.gg/wVN53HyJqh" target="_blank" rel="noopener noreferrer" className="bg-[#5165F6] mb-1 rounded">
					<img src={discord} alt="Discord Logo" className="h-7 w-7 -mb-1" />
					</a>
					<a href="https://twitter.com/BACKSTORM_off" target="_blank" rel="noopener noreferrer">
					<img src={twitter} alt="Twitter Logo" className="h-7 w-7" />
					</a>
					<a href="https://www.instagram.com/backstorm_off/" target="_blank" rel="noopener noreferrer">
					<img src={instagram} alt="Instagram Logo" className="h-7 w-7" />
					</a>
				</div>
			</div>
			<div className="sm:text-center my-6 px-6 text-xs">
			Backstorm.
			Backstorm © 2023
			</div>
		</div>
      </div>
    </footer>
        );
}
export default Footer;
