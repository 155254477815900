import Question from "../Assets/Questions";

function FAQ(){
    return(
        <>
        <b className="flex justify-center text-center text-4xl mb-12" style={{
      background: 'linear-gradient(to right, #A9544F, #8C3294)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      }}>FAQ </b>
        <section className="flex justify-center flex-col items-center rounded-lg overflow-hidden">
            <Question title={'Comment devenir Gestminter ?'} answer={'Acheter votre licence sur Backstorm.org.'}/>
            <Question title={'Une identité Backstorm?'} answer={'Vous pouvez découvrir notre gamme de vetements sur Bakcstorm.shop'}/>
        </section>
		</>
        );
}
export default FAQ;

