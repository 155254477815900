import {
  Routes,
  Route,
} from "react-router-dom";
import BackstormHome from "./components/BackstormHome";
import SignUpModal from "./components/SignUpModal";
import SignInModal from "./components/SignInModal";
import Myre from "./components/Myre";
import NavBar from "./components/NavBar";
import NavVariation from "./Assets/NavVariation";
import PrivateHome from "./components/privatehome";
import Private from "./components/Private";
import ProductPage from "./components/ProductPage";
import Entreprise from "./components/Entreprise";
import Adjevision from "./components/Adjevision";
import Backstorm from "./components/Backstorm";
import Myre2 from "./components/Myre2";
import Sabulle from "./components/Sabulle";
import TelloWeb from "./components/Telloweb";
import Graceeat from "./components/GraceEat";
import NotFound from "./components/NotFound";

function App() {
  return (
    <>
    <SignUpModal />
    <SignInModal />
    <NavBar />
    <NavVariation />
    <Routes>
      <Route path="/" element={<BackstormHome />} />
      <Route path="/Myre" element={<Myre />} />
      <Route path="/Backstorm" element={<Backstorm />} />
      <Route path ="/AAJVisionImmobilier" element={<Adjevision />} />
      <Route path ="/Femada" element={<TelloWeb />} />
      <Route path ="/Semena" element={<Graceeat />} />
      <Route path="/Prodetection" element={<Myre2 />} />
      <Route path="/private" element={<Private />} />
      <Route path="/Sabulle" element={<Sabulle />} />
      <Route path="/mon-compte" element={<PrivateHome />} />
      <Route path="/CAR" element={<ProductPage />} />
      <Route path="/Actualites" element={<Entreprise />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
    </>
  );
}
export default App;
