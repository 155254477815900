import React from "react";

const Hero = () => {
    return (
		<section className="mb-12 mt-7">
		<div className="bg-[#212529] rounded-lg shadow-lg px-4 sm:px-6 md:px-10 lg:px-14 py-0">
		<b className="flex justify-center text-center text-3xl sm:text-4xl pt-12 mb-12" style={{
		   background: 'linear-gradient(to right, #A9544F, #8C3294)',
		   WebkitBackgroundClip: 'text',
		   color: 'transparent',
		   }}>
			 Backstorm est un écosystème d'entrepreneurs reposant sur 3 piliers fondamentaux : 
		   </b>          
     <p className="mt-4 text-center text-white" >1. Financement 
     </p>
     <p className="mt-0  text-white" >Le financement par la Place de marché privé des CART , la plateforme backstorm.fr ou en deux clique on va pouvoir investir sur les différentes entreprises de l’ecosysteme ,a noter que backstorm est actionnaire de chaque entreprises lister.
     Ces C.A.R.T (contrats à rendements trimestriel) sont des valeurs mobilières qui représentent des fractions des bénéfices que génèrent chaque société lister sur backstorm.fr que pourront acheter, vendre et échanger chaque adhérant détenant une licence dans l’écosystème. 
     </p>
     <p className="mt-4 text-center text-white" >2. La visibilité 
     </p>
     <p className="mt-0 text-white" >Un média exclusif a Backstorm est crée, par le biais des réseaux sociaux ainsi que d’un plateau TV. 
Celui-ci permet d’informer toute la communauté, lors de diverses émissions, sur tout ce qui concerne l’écosystème, à savoir l’actualité des entreprises, les projets à venir, les événements futurs, etc. 
Ces émissions permettent également à chaque entrepreneur ou entreprise de mettre en avant son activité et de bénéficier d’actions de communication sur les différents réseaux sociaux du média.
     </p>
     <p className="mt-4 text-center text-white" >3. La force de vente / formation / gesminter 
     </p>
     <p className="mt-0 text-white" >Enfin, la partie essentielle de notre écosystème concerne le développement de nos membres, appelés les gestminters. 

Plusieurs options s’offrent à nos adhérents pour acquérir une licence dite de Gestminter (accessible sur backstorm.org) qui permet, suivant la licence choisie, de participer plus ou moins activement aux activités internes de l’écosystème.
     </p>
     <p className="mt-0 text-white" >
Une première licence (Gestminter passif) est accessible à 89€ permet d’accéder à la place des marchés privé (backstorm.fr) et peut ainsi acheter et revendre les titres d’entreprises C.A.R.T. 
     </p>
     <p className="mt-2 text-white" >
     Une seconde  licence (Gestminter actif) est proposée à 459€ permettant, en plus des services inclus dans la première licence, d’accéder aux différents mandats des entreprises afin de vendre ses produits ou services. Ces membres participent donc activement au développement interne et bénéficient des formations de chaque entreprise, pour profiter de leur expérience et savoir-faire dans différents domaines. Cela permet à l’adhérent de pouvoir tirer profit de chaque entreprises de l’écosystème.
     </p>
     <p className="mt-2 text-white" >
     Une troisième licence (Gestminter indépendant)3 concerne les entreprises souhaitant bénéficier des services intégrés à Backstorm sans être directement listée sur la place des marchés.  ( backstorm ne détient aucune participation dans l’entrerprise).
     </p>
     <p className="mt-2 text-white" >
     Une quatrième et dernière licence (Gestminter Founder) est ouverte pour une étude personnalisée du projet, que ce soit un particulier ou une entreprise.
     </p>
         </div>
         </section>
    );
  };
  
  export default Hero;