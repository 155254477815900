import React from "react";
import { useNavigate } from 'react-router-dom';

  
const Activite = () => {
    const navigate = useNavigate();
    const handleBuy = () => {
      navigate('/Productpage');
    }

    const handleButtonClick = () => {
      navigate('/CAR');
    };
    
  return (
    <section className="flex justify-center flex-col mb-24">
   <div className="bg-whitesmoke flex justify-center flex-col items-center" data-aos="fade-up">
   <b className="flex justify-center text-center text-3xl sm:text-4xl mb-12" style={{
      background: 'linear-gradient(to right, #A9544F, #8C3294)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      }}>
        Découvrez les contrats à rendement
      </b>
      <p className="flex justify-center text-center sm:text-center mt-0">Les contrats à rendement sont des valeurs mobilières qui représentent une fraction des bénéfices d'une entreprise cotée sur Backstorm. Contrairement aux actions traditionnelles, qui confèrent généralement des droits de propriété et de vote aux actionnaires, les contrats à rendement sont spécifiquement conçus pour offrir un rendement financier basé sur les bénéfices de l'entreprise.
</p>
  <button onClick={handleButtonClick} className="cursor-pointer rounded-md font-montserrat font-medium px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-base text-white mt-6">
    En savoir plus
  </button>
    </div>
    </section>
  );
};

export default Activite;
