import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

function Question(props) {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <div className="w-full">
      <div className="flex items-center py-5 px-6 sm:px-8 bg-white cursor-pointer hover:bg-zinc-200/60" style={{ borderBottom: 'solid 1px #f6f6f9' }} onClick={toggleAnswer}>
        <div className="mr-5 -mb-1">
          {showAnswer ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
			<path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
		  </svg>		  
          ) : (
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
			<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
			</svg>	  
          )}
        </div>
        {props.title}
      </div>
      {showAnswer && (
        <div className="bg-white p-1 px-6 sm:px-20" style={{ borderBottom: 'solid 1px #f6f6f9' }}>
          <p>{props.answer}</p>
        </div>
      )}
    </div>
  );
}

export default Question;
