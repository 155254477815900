import CAR2 from './CAR2';
import Activite from './Activite';
import Actualite from './Actualite';
import FAQ from './FAQ';
import Footer from './Footer';

const BackstormHome = () => {
  
  return (
    <div className="bg-whitesmoke font-montserrat text-[17px] leading-6">
		<div className='container mx-auto'>
			<div className='px-6'>
				<CAR2 />
				<Activite />
				<Actualite />
				<FAQ />
			</div>
		</div>
		<Footer />
    </div>
  );
};

export default BackstormHome;
