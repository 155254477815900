import React from "react";

const Hero2 = () => {
    return (
		<section className="mb-12">
		<div className="bg-[#212529] rounded-lg shadow-lg px-4 sm:px-6 md:px-10 lg:px-14 py-0">
		<b className="flex justify-center text-center text-3xl sm:text-4xl pt-12 mb-12" style={{
		   background: 'linear-gradient(to right, #A9544F, #8C3294)',
		   WebkitBackgroundClip: 'text',
		   color: 'transparent',
		   }}>
			 Découvrez les contrats à rendement Trimestriels
		   </b>          
             <p className="mt-0 text-white text-center" >Les contrats à rendement sont des valeurs mobilières qui représentent une fraction des bénéfices d'une entreprise cotée sur Backstorm. Contrairement aux actions traditionnelles, qui confèrent des droits de propriété et de vote aux actionnaires, les contrats à rendement sont spécifiquement conçus pour offrir un rendement financier basé sur les bénéfices de l'entreprise. Ils sont donc considérés comme une alternative aux actions classiques pour les investisseurs qui souhaitent se concentrer principalement sur les rendements financiers plutôt que sur le rachat de parts social d’une entreprise.
Prenons l'exemple de l'entreprise Apple, dont les actions sont cotées en bourse. pour investir sur la société Apple les investisseurs peuvent acheter des actions sur le marché (bourse) , pour investir dans le développement de Bamboo Cbd les investisseurs peuvent acheter des contrats a rendement sur backstorm.  Les contrats à rendement de Bamboocbd permettent aux investisseurs de participer a l’évolution de l’entreprise en contrepartie d’un intéressement au bénéfice de celle-ci, sans détenir de parts dans l’entreprise.
     </p>
     <p className="mt-4 text-white text-center" >Lorsque vous investissez dans des contrats à rendement, votre rendement financier dépendra directement des performances financières de l'entreprise émettrice, dans ce cas, Bamboocbd. Si les bénéfices de Bamboocbd augmentent, votre rendement potentiel augmentera également. Cependant, si les bénéfices diminuent, votre rendement pourrait en être affecté de manière négative.
Il est important de noter que les contrats à rendement peuvent être structurés de différentes manières, et les modalités spécifiques peuvent varier d'une entreprise à l'autre. Par exemple, les contrats à rendement peuvent comporter des clauses de protection pour les investisseurs, telles que des mécanismes de limitation des pertes. Il est essentiel de comprendre les caractéristiques spécifiques des contrats à rendement avant de prendre une décision d'investissement.
     </p>
     <p className="mt-4 text-white text-center" >Voici comment fonctionne Backstorm :
     </p>
     <p className="mt-0 text-white text-center" >1. Accès restreint : </p>
     <p className="mt-0 text-white text-center" >Backstorm.fr est un marché privé exclusivement réservé aux  gesminters adhérents de l'écosystème Backstorm. Seuls ces investisseurs qualifiés peuvent accéder et investir sur cette place de marché.
     </p>
     <p className="mt-0 text-white text-center" >2. Placement privé :</p>
     <p className="mt-0 text-white text-center" >Ces entreprises (dont BACKSTORM est actionnaire) non cotées qui souhaitent lever des fonds sur Backstorm procèdent à un placement privé de leurs CART, sans passer par un appel public à l'épargne. Elles peuvent choisir de ne s'adresser qu'à un nombre limité de gesminters adhérents.
     </p>
     <p className="mt-0 text-white text-center" >3. Exemption des règles de l'appel public :</p>
     <p className="mt-0 text-white text-center" >En restant dans le cadre du placement privé, les entreprises émettant des CART sur Backstorm sont exonérées des obligations de contrôle et d'information imposées par les autorités de marché dans le cas d'un appel public à l'épargne.
     </p>
     <p className="mt-0 text-white text-center" >4. Droits de souscription :</p>
     <p className="mt-0 text-white text-center" >L'émission de CART sur Backstorm peut être assortie ou non de droits préférentiels de souscription, selon les choix de l'entreprise émettrice.
     </p>
     <p className="mt-0 text-white text-center" >5. Confidentialité :</p>
     <p className="mt-0 text-white text-center" >Les informations relatives aux transactions et aux participations sur Backstorm sont maintenues confidentielles, conformément aux attentes des gesminters adhérents participant à ce marché privé.
     </p>
     <p className="mt-0 text-white text-center" >6. Adhésion :</p>
     <p className="mt-0 text-white text-center" >Seuls les  gesminters ayant adhéré à l'écosystème Backstorm peuvent investir dans les CART émis sur cette place de marché privée.
     </p>
     <p className="mt-0 text-white text-center" >7. Capital : 
     </p>
     <p className="mt-0 text-white text-center" >Backstorm est actionnaire de chaque entreprise listé (cotée).
     </p>
         </div>
         </section>
    );
  };
  
  export default Hero2;