import React, { useEffect, useState } from 'react';
import { ref, onValue, getDatabase } from "firebase/database";
import {useNavigate} from 'react-router-dom';
import projetetr from '../Assets/Images/proetetr.png';
import rendements from '../Assets/Images/rendementsetr.png';
import potentiel from '../Assets/Images/forpotentiel.png';

const CAR2 = () => {
  const database = getDatabase();
  const [entrepriseData, setEntrepriseData] = useState([]);
  const navigate = useNavigate();

  function link(url) {
	navigate(url)
}

  useEffect(() => {
    const callAllInformations = ref(database, `globalInformation`);

    function unsubscribeInformations() {
      onValue(callAllInformations, (snapshot) => {
        const informations = snapshot.val();

        if (informations && informations.informationArray) {
          const entrepriseDataArray = informations.informationArray.map((entrepriseInfo) => ({
            name: entrepriseInfo.name,
            quantiteBnf: entrepriseInfo.quantiteBnf,
            variation: entrepriseInfo.variation,
            lastPrice: entrepriseInfo.lastPrice,
            logo: entrepriseInfo.logo,
            link: entrepriseInfo.link,
            sector: entrepriseInfo.sector,
          }));

          setEntrepriseData(entrepriseDataArray);
        }
      });
    }

    unsubscribeInformations();

  }, []);
  

  return (
    <div className="mb-24" data-aos="fade-up">
	<div className='flex justify-center flex-col items-center relative'  
    style={{
        backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/teste-acdde.appspot.com/o/drapeaux%20du%20mondeeeee.png?alt=media&token=c740a01b-67b7-4a58-97cc-f0370b9fc6d8)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundClip: 'border-box',
        backgroundRepeat: 'no-repeat'
    }}>
    <div className='mt-10 mb-1 flex items-center relative'>
        <a className='font-bold text-[#E956AF] text-[30px]' style={{ textShadow: '1px 1px 1px rgba(0, 0, 0, 1)' }}>+400</a>
        <span className="text-green-500 font-bold ml-1 mb-2.5 text-[10px]" style={{ textShadow: '1px 1px 1px rgba(0, 0, 0, 1.5)' }}>+97%</span>
    </div>
    <div className='mt-0 mb-3'>
        <a className='text-dark font-bold text-sm' style={{ textShadow: '0px 0px 0px rgba(0, 0, 0, 0.5)' }}>Gestminters ont rejoint Backstorm</a>
    </div>
    <button onClick={() => link("CAR")} className="cursor-pointer rounded-full py-1.5 px-8 mb-5 font-medium bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-lg text-white">Commencer</button>
</div>

	<div className='max-sm:-mx-6'>
		<table className="table-auto w-full bg-white sm:rounded-lg border-collapse text-sm sm:text-base">
			<thead className="text-dark">
			<tr style={{ borderBottom: 'solid 1px #f6f6f9' }}>
				<th className="text-left py-6 px-3 sm:px-12 font-bold">Nom</th>
				<th className="text-right py-6 px-3 sm:px-12 font-bold">Prix</th>
				<th className="text-right py-6 px-3 sm:px-12 font-bold">Variation</th>
			</tr>
			</thead>
			<tbody className="text-gray-700">
			{entrepriseData.map((entreprise, index) => (
				<tr key={index} className='hover:bg-zinc-200/60 cursor-pointer' style={{ borderBottom: 'solid 1px #f6f6f9' }} onClick={() => link(`/${entreprise.name}`)}>
				<td className="py-2 px-3 sm:px-12">
					<div className="">
					
					<div className="flex items-center text-black">
					<img src={`${entreprise.logo}`} width="45" height="45" alt="Logo" className='mr-2 sm:mr-4'/> {entreprise.name}
					</div>
					</div>
				</td>
				<td className="text-[#000000] py-2 px-3 sm:px-12 text-right">{`${parseFloat(entreprise.lastPrice).toFixed(2)}€`}</td>
				<td className="text-right py-2 px-3 sm:px-12">
					<span className="text-[#000000] text-center">
					<h3
						className="text-[#62C354] font-medium"
						style={{ display: 'inline-block' }}
					>{`${parseFloat(entreprise.variation).toFixed(2)}%`}</h3>
					</span>
				</td>
				</tr>
			))}
			</tbody>
		</table>    
      <div className="flex justify-between items-center mt-4 px-3 sm:px-12">
            <div className="flex flex-col items-center">
                <img src={rendements} alt="Description image 1" className="w-10 h-10 sm:w-10 sm:h-10 mb-0"/>
                <p className="text-gray-700 text-sm text-center">C.A.R.T avec rendements</p>
            </div>
            <div className="flex flex-col items-center">
                <img src={projetetr} alt="Description image 2" className="w-10 h-10 sm:w-10 sm:h-10 mb-0"/>
                <p className="text-gray-700 text-sm text-center">Projet d'entreprise</p>
            </div>
            <div className="flex flex-col items-center">
                <img src={potentiel} alt="Description image 3" className="w-10 h-10 sm:w-10 sm:h-10 mb-0"/>
                <p className="text-gray-700 text-sm text-center">C.A.R.T à fort Potentiel</p>
            </div>
        </div>
	</div>
</div>


  );
};

export default CAR2;
