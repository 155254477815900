import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from "../context/userContext";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { useNavigate, useLocation  } from 'react-router-dom';
import deco from "../Assets/Images/logodeco.png";
import { auth } from "../firebase-config";
import menu from "../Assets/Images/menu.png"
import BKS from "../Assets/Images/logo-color.png";
import { useLayoutEffect } from "react";

const NavBar = () => {
    const { toggleModals, currentUser } = useContext(UserContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [displayMenu, setDisplayMenu] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation ();

    useEffect(() => {
      setIsLoggedIn(currentUser !== null);
    }, [currentUser]);

	useLayoutEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [location.pathname]);


    const handleClickButton1 = () => {
      navigate('/');
    };
    
    const handleClickButton2 = () => {
      navigate('/ProductPage');
    };

    const handleClickButton3 = () => {
      navigate('./Entreprise');
    };

    const showMenu = () => {
      setDisplayMenu(!displayMenu);
    }

    const logOut = async () => {
      try {
        await signOut(auth);
        navigate("/");
      } catch {
        alert("For some reasons we can't deconnect, please check your internet connexion and retry.");
      }
    };
    return (
	<>
	<div className='h-[63.5px] sm:h-[84px]'></div>
     <nav className="bg-[#212529] text-lg text-darkgray font-montserrat fixed top-0 left-0 right-0 z-50">
		<div className="container mx-auto">
			<div className='flex flex-col lg:flex-row px-6'>
				<div className="flex items-center justify-between lg:justify-start lg:w-1/3 pt-2.5 pb-1.5">
					<Link to="/" className="navbar-brand" onClick={() => setMenuOpen(false)}>
						<img src={BKS} width="160" height="40" className="" alt="Logo Backstorm" />
					</Link>
					<button className="bg-[#212529] lg:hidden cursor-pointer -mr-2" onClick={() => setMenuOpen(!menuOpen)}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-9 h-9 text-white">
					<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
					</svg>
					</button>
				</div>
				<div className={`flex flex-col lg:flex-row ${!menuOpen ? 'hidden' : ''} lg:block lg:flex-grow lg:text-right lg:space-x-12`}>
					<Link to="/" className="no-underline text-darkgray max-lg:mt-6 hover:text-white" onClick={() => setMenuOpen(!menuOpen)}>
					<span className="cursor-pointer">Accueil</span>
					</Link>
					<Link to="/CAR" className="no-underline text-darkgray max-lg:mt-6 hover:text-white" onClick={() => setMenuOpen(!menuOpen)}>
					<span className="cursor-pointer">Les CART</span>
					</Link>
					<Link to="/Actualites" className="no-underline text-darkgray max-lg:mt-6 hover:text-white" onClick={() => setMenuOpen(!menuOpen)}>
					<span className="cursor-pointer rounded">Actualités</span>
					</Link>
					{isLoggedIn ? (
								<span className='inline-flex items-center'>
								<button onClick={() => {navigate('/mon-compte'); setMenuOpen(!menuOpen)}} type="button" className="cursor-pointer rounded-md w-full my-6 text-lg bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr font-medium font-montserrat text-white py-1 px-4">Mon compte</button>
								<button onClick={() => {logOut('/'); setMenuOpen(!menuOpen)}} type="button" className="cursor-pointer bg-[#212529] ml-6 group">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-darkgray -mb-1 group-hover:text-red-500">
								<path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
								</svg>
								</button>
								</span>
							) : (
								<button className="cursor-pointer rounded-md my-6 text-lg bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr font-medium font-montserrat text-white py-1 px-4"
								onClick={() => { toggleModals("signIn"); setMenuOpen(!menuOpen)}}>
								Connexion
								</button>
							)}
				</div>
			</div>
		</div>
    </nav>
	</>
  );
}
  
  export default NavBar;