import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../context/userContext';
import TokenPurchaseForm from '../context/TokenPurchaseFrom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import Tokensale from "../context/Tokensale";
import moment from 'moment';
import Footer from './Footer';

export default function PrivateHome() {
  const user = useContext(UserContext);
  const [showPurchaseForm, setShowPurchaseForm] = useState(false);
  const handleClosePurchaseForm = () => {
    setShowPurchaseForm(false);
  };
  const [showSaleForm, setShowSaleForm] = useState(false);
  const handleCloseSaleForm = () => {
    setShowSaleForm(false);
  };
  const [newTotalBalances, setNewTotalBalances] = useState([]);
  const [newTotalBalances2, setNewTotalBalances2] = useState([]);
  const [newTotalBalances3, setNewTotalBalances3] = useState([]);
  const [newTotalBalances4, setNewTotalBalances4] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [resetEmail, setResetEmail] = useState('');
  const [lastPrice, setLastPrice] = useState(0);
  const [variation, setVariation] = useState(0);
  const [tokenTransactions, setTokenTransactions] = useState([]);

  useEffect(() => {
    if (user) {
      const database = getDatabase();
      const userId = user.currentUser.uid;
      const totalBalanceRef = ref(database, `totalBalance/${userId}/balance`);
      const newTotalBalanceRef = ref(database, `newTotalBalance/${userId}`);
      const newTotalBalanceRef2 = ref(database, `newTotalBalance2/${userId}`);
      const newTotalBalanceRef3 = ref(database, `newTotalBalance3/${userId}`);
      const newTotalBalanceRef4 = ref(database, `newTotalBalance4/${userId}`);
      const allInformationsRef = ref(database, `globalInformation`);
      const newTokenTransactionRef = ref(database, `transactions/${userId}`);

      const unsubscribePromises = [];

      new Promise((resolve) => {
        const unsubscribeTransactions = onValue(newTokenTransactionRef, (snapshot) => {
          const TokenTransactions = snapshot.val();
          setTokenTransactions(TokenTransactions);
          resolve();
        });
        unsubscribePromises.push(unsubscribeTransactions);
      });

      new Promise((resolve) => {
        const unsubscribeInformations = onValue(allInformationsRef, (snapshot) => {
          const informations = snapshot.val();
          setLastPrice(informations.informationArray[0].lastPrice);
          setVariation(informations.informationArray[0].variation);
          resolve();
        });
        unsubscribePromises.push(unsubscribeInformations);
      });

      new Promise((resolve) => {
        const unsubscribeTotalBalance = onValue(totalBalanceRef, (snapshot) => {
          const balance = snapshot.val();
          setTotalBalance(balance);
          resolve();
        });
        unsubscribePromises.push(unsubscribeTotalBalance);
      });

      new Promise((resolve) => {
        const unsubscribenewTokenBalance = onValue(newTotalBalanceRef, (snapshot) => {
          setNewTotalBalances([snapshot.val()]);
          resolve();
        });
        unsubscribePromises.push(unsubscribenewTokenBalance);
      });

      new Promise((resolve) => {
        const unsubscribenewTokenBalance2 = onValue(newTotalBalanceRef2, (snapshot) => {
          setNewTotalBalances2([snapshot.val()]);
          resolve();
        });
        unsubscribePromises.push(unsubscribenewTokenBalance2);
      });

      new Promise((resolve) => {
        const unsubscribenewTokenBalance3 = onValue(newTotalBalanceRef3, (snapshot) => {
          setNewTotalBalances3([snapshot.val()]);
          resolve();
        });
        unsubscribePromises.push(unsubscribenewTokenBalance3);
      });

      new Promise((resolve) => {
        const unsubscribenewTokenBalance4 = onValue(newTotalBalanceRef4, (snapshot) => {
          setNewTotalBalances4([snapshot.val()]);
          resolve();
        });
        unsubscribePromises.push(unsubscribenewTokenBalance4);
      });

      Promise.all(unsubscribePromises).then(() => {
        console.log('All unsubscribed');
      });

      return () => {
        unsubscribePromises.forEach((unsubscribe) => {
          unsubscribe();
        });
      };
    }
  }, [user]);

  // Calcul de la somme des valeurs des C.A.R.T
  const calculateTotalCartValue = () => {
    const allBalances = newTotalBalances.concat(newTotalBalances2, newTotalBalances3, newTotalBalances4);
    return allBalances.reduce((sum, balance) => sum + (balance?.balance || 0) * lastPrice, 0);
  };

  const totalCartValue = calculateTotalCartValue();

  const handleClickButton = () => {
    setShowPurchaseForm(true);
  };

  const handleClickSaleButton = () => {
    setShowSaleForm(true);
  };

  const handleResetPassword = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        alert('Un e-mail de réinitialisation a été envoyé à votre adresse e-mail.');
      })
      .catch((error) => {
        console.error(error);
        alert("Une erreur s'est produite lors de la réinitialisation du mot de passe. Veuillez réessayer.");
      });
  };

  const handleSell = () => {
    setShowSaleForm(true);
  };

  const handleSell2 = () => {
    //setShowSaleForm(true);
  };

  const getCompanyNameByIndex = (index) => {
    const length1 = newTotalBalances.length;
    const length2 = newTotalBalances2.length;
    const length3 = newTotalBalances3.length;
    const length4 = newTotalBalances4.length;

    if (index < length1) {
      return 'BambooCBD';
    } else if (index < length1 + length2) {
      return 'AAJ Vision Immobilier';
    } else if (index < length1 + length2 + length3) {
      return 'Backstorm';
    } else if (index < length1 + length2 + length3 + length4) {
      return 'Prodetection';
    } else {
      return 'AAJ Vision Immobilier'; // Valeur par défaut si l'index dépasse tous les tableaux
    }
  };

  return (
    <div className="bg-whitesmoke font-montserrat text-[17px] leading-6">
      <div className='container mx-auto'>
        <div className='px-6'>
          <h1 className="text-center text-2xl sm:text-3xl mt-0 pt-5 mb-6 font-bold">Votre compte</h1>
          <div className='bg-white rounded-lg p-6 sm:px-12 sm:py-6'>
            <div className='grid grid-cols-1 sm:grid-cols-2'>
              <div className="font-soft mb-1">Valeur totale&nbsp;:&nbsp;</div>
              <div className="flex items-center">
                <div className="font-semibold text-[30px] text-center mb-3 my-0">
                  {`${parseFloat(totalBalance + totalCartValue).toFixed(2)} €`} {/* Valeur totale = disponible + C.A.R.T */}
                </div>
              </div>
              <div className='flex justify-between sm:justify-end max-sm:pt-4'>
                <button onClick={handleClickButton} className="cursor-pointer rounded-md py-2 px-7 font-medium font-montserrat bg-gradient-to-br from-green-700 to-green-500 hover:bg-gradient-to-tr text-base text-white">
                  Dépôt
                </button>
                <button onClick={handleClickSaleButton} className="cursor-pointer rounded-md py-2 px-7 ml-4 font-medium font-montserrat bg-gradient-to-br from-slate-700 to-slate-500 hover:bg-gradient-to-tr text-base text-white">
                  Retrait
                </button>
              </div>
              <div className='flex items-center mt-5'>
                <div className="font-soft">Disponible&nbsp;:&nbsp;</div>
                <div className="font-semibold text-xl my-0">
                  {`${parseFloat(totalBalance).toFixed(2)} €`}
                </div>
              </div>
            </div>
          </div>

          <h2 className="text-center text-2xl sm:text-3xl mt-0 pt-6 mb-6 font-bold">Liste de vos C.A.R.T</h2>
          <div className='overflow-x-auto'>
            <table className="table-auto w-full bg-white rounded-lg border-collapse text-sm sm:text-base">
              <thead>
                <tr className='' style={{ borderBottom: 'solid 1px #f6f6f9' }}>
                  <th className='text-left py-6 px-3 sm:px-12 font-bold'>Nombre de&nbsp;CART</th>
                  <th className='text-left py-6 px-3 sm:px-12 font-bold'>Nom Entreprise</th>
                  <th className='text-right py-6 px-3 sm:px-12 font-bold'>Variation</th>
                  <th className='text-right py-6 px-3 sm:px-12 font-bold'>Valeur</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="">
                {newTotalBalances
                  .concat(newTotalBalances2)
                  .concat(newTotalBalances3)
                  .concat(newTotalBalances4)
                  .map((balance, index) => (
                    <tr key={index} style={{ borderBottom: 'solid 1px #f6f6f9' }}>
                      <td className='py-2 px-3 sm:px-12'>{parseFloat(balance?.balance).toFixed(3)}</td>
                      <td className='py-2 px-3 sm:px-12'>{getCompanyNameByIndex(index)}</td>
                      <td className="py-2 px-3 sm:px-12 text-right text-green-500 font-medium">{`${parseFloat(variation).toFixed(3)}%`}</td>
                      <td className='py-2 px-3 sm:px-12 text-right'>{`${parseFloat(lastPrice * balance?.balance || 0).toFixed(2)}€`}</td>
                      <td className='py-2 px-3 sm:px-12 text-right'>
                        <button onClick={handleSell2} className="cursor-pointer rounded-md py-2 px-5 font-medium font-montserrat bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-base text-white">
                          Vendre
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <h2 className="text-center text-2xl sm:text-3xl mt-0 pt-6 mb-6 font-bold">Mes Transactions</h2>
          <div className='overflow-x-auto'>
            <table className="table-auto w-full bg-white rounded-lg border-collapse text-sm sm:text-base">
              <thead>
                <tr className='' style={{ borderBottom: 'solid 1px #f6f6f9' }}>
                  <th className='text-left py-6 px-3 sm:px-12 font-bold'>Solde</th>
                  <th className='text-left py-6 px-3 sm:px-12 font-bold'>Nom Entreprise</th>
                  <th className='text-right py-6 px-3 sm:px-12 font-bold'>Valeur</th>
                  <th className='text-right py-6 px-3 sm:px-12 font-bold'>Date</th>
                </tr>
              </thead>
              <tbody className=''>
                {tokenTransactions && Object.values(tokenTransactions).reverse().map((transaction, index) => (
                  <tr key={index} style={{ borderBottom: 'solid 1px #f6f6f9' }}>
                    <td className='py-2 px-3 sm:px-12'>{parseFloat(transaction.amount).toFixed(2)}</td>
                    <td className='py-2 px-3 sm:px-12'>{transaction.EntrepriseName}</td>
                    <td className='py-2 px-3 sm:px-12 text-right'>{`${parseFloat(transaction.amount).toFixed(2)}€`}</td>
                    <td className='py-2 px-3 sm:px-12 text-right'>{moment(transaction.timestamp).format('lll')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h2 className="text-center text-2xl sm:text-3xl mt-0 pt-6 mb-6 font-bold">Mot de passe</h2>
          <div className='bg-white rounded-lg p-6 sm:px-12 sm:py-6'>
            <div className='grid grid-cols-1 sm:grid-cols-2'>
              <div>
                <label htmlFor="resetEmail" className="mr-4">Adresse&nbsp;email&nbsp;:</label>
                <input
                  type="email"
                  id="resetEmail"
                  className="text-[17px] max-sm:mt-1 w-full sm:w-80 rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </div>
              <div className='flex justify-between sm:justify-end max-sm:pt-4'>
                <button onClick={handleResetPassword} className="font-montserrat cursor-pointer rounded-md py-2 px-5 font-medium bg-gradient-to-br from-slate-700 to-slate-500 hover:bg-gradient-to-tr text-base text-white">
                  Réinitialiser&nbsp;le&nbsp;mot&nbsp;de&nbsp;passe
                </button>
              </div>
            </div>
          </div>

          <div className="pl-5">
            {showPurchaseForm && <TokenPurchaseForm onClose={handleClosePurchaseForm} />}
            {showSaleForm && <Tokensale onClose={handleCloseSaleForm} totalBalance={totalBalance} />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
