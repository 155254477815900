import React, { useContext, useRef, useState } from "react";
import { UserContext } from "../context/userContext";
import {useNavigate} from "react-router-dom";

export default function SignUpModal() {
  
  const { modalState, toggleModals, signUp } = useContext(UserContext);
  const navigate = useNavigate(); 
  const [validation, setValidation] = useState("");
  const inputs = useRef([]);
  const formRef = useRef();
  const addInputs = el => {
    console.log(el)
    if(el && !inputs.current.includes(el)){
      inputs.current.push(el)
    }
  }  
  const handleForm = async (e) => {
    e.preventDefault()
    console.log(inputs.current[1].value)
    if((inputs.current[1].value.length < 6 || inputs.current[2].value.length) < 6) {
      setValidation("6 characters minimun")
      return;
    }
    else if(inputs.current[1].value !== inputs.current[2].value) {
      setValidation("Pas les mêmes mot de passe")
      return;
    }

    try {
      console.log('la')
      const cred = await signUp(
        inputs.current[0].value,
        inputs.current[1].value
      )
      setValidation("")
      console.log(cred);
      toggleModals("close")
      navigate("/")

    } catch (err) {

      if(err.code === "auth/invalid-email") {
        setValidation("Email format invalid")
      }
      
      if(err.code === "auth/email-already-in-use") {
        setValidation("Email already used")
      }
 
    }

  }

  const closeModal = () => {
    setValidation("");
    toggleModals("close");
  };

  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains("bg-black")) {
      closeModal();
    }
  };

  return (
    <>
      {modalState.signUpModal && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center font-montserrat bg-black bg-opacity-50 z-10"
          onClick={handleBackgroundClick}
        >
          <div className="bg-whitesmoke rounded-xl overflow-hidden shadow-lg w-[500px]">
            <div className="bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] flex text-light justify-center mb-12">
              <h5 className="text-xl font-medium text-white">
                S'inscrire
              </h5>
              <span style={{ cursor: 'pointer' }} onClick={closeModal}>
                <i className="fa-solid fa-xmark fa-1x"></i>
              </span>
            </div>
            <form ref={formRef} onSubmit={handleForm}>
              <div className="text-light my-4 px-7">
                <input
                  ref={el => addInputs(el)}
                  name="email"
                  required
                  type="email"
                  className="w-full text-[17px] rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat"
                  id="signUpEmail"
                  placeholder="Adresse Email"
                />
              </div>
              <div className="mb-3 px-7">
                <input
                  ref={el => addInputs(el)}
                  name="pwd"
                  required
                  type="password"
                  className="w-full text-[17px] rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat"
                  id="signUpPwd"
                  placeholder="Mot de passe"
                />
                <input
                  ref={el => addInputs(el)}
                  name="pwd"
                  required
                  type="password"
                  className="w-full text-[17px] rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat mt-4"
                  id="repeatPwd"
                  placeholder="Confirmez le mot de passe"
                />

                <p className="text-center text-[#b91c1c] m-4">{validation}</p>
              </div>
			  <div className='flex pb-4'>
				<button className="cursor-pointer mx-auto rounded-md font-montserrat font-medium px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-base text-white mt-6">
					Inscription
				</button>
			</div>
              <div className="flex flex-col text-center mt-12">
                <b className="text-slate-500 font-normal m-2">Vous avez déjà un compte ?</b>
                <b className="cursor-pointer font-semibold mt-1 mb-5" onClick={() => toggleModals("signIn")}>Se connecter</b>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
