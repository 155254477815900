import Actualite2 from './Actualite2';
import Footer from './Footer';

const Entreprise = () => {
  
  return (
    <div className="bg-whitesmoke font-montserrat text-[17px] leading-6">
		<div className='container mx-auto'>
			<div className='px-6'>
 			     <Actualite2 />
			</div>
		</div>
		<Footer />
    </div>
  );
};

export default Entreprise;