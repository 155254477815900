import React, { useRef, useEffect } from 'react';
import { getDatabase, ref, get } from "firebase/database";
import * as d3 from 'd3';
import moment from 'moment';

const Chart3 = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const width = 1400;
    const height = 500;
    const marginTop = 20;
    const marginRight = 30;
    const marginBottom = 30;
    const marginLeft = 40;

    const database = getDatabase();
    const callTokenTransactions = ref(database, `newTokenTransactions3`);

    const svg = d3
      .select(chartRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .style("max-width", "100%")
      .style("height", "auto")
      .style("height", "intrinsic");

    const fetchData = async () => {
      try {
        const snapshot = await get(callTokenTransactions);
        const response = snapshot.val();
        
        console.log("Données brutes récupérées de Firebase:", response);

        if (response) {
          const data = [];
          Object.values(response).forEach((transaction) => {
            const transactionData = Object.values(transaction).map((item) => item);
            transactionData.forEach((t) => {
              data.push(t);
            });
          });

          console.log("Données transformées après mappage:", data);

          const candlestickData = data.map((transaction, index, array) => {
            const close = parseFloat(transaction.lastPrice) || 0;
            const open = array[index - 1] ? parseFloat(array[index - 1].lastPrice) : close;
            const high = Math.max(open, close);
            const low = Math.min(open, close);

            return {
              date: new Date(moment(transaction.timestamp).format('llll')),
              open: open,
              high: high,
              low: low,
              close: close
            };
          });

          console.log("Données formatées pour le graphique en chandelier:", candlestickData);

          const dataSubset = candlestickData.slice(0, 90);

          // Ajuster la largeur des chandeliers en augmentant le padding
          const x = d3.scaleBand()
            .domain(dataSubset.map(d => d.date))
            .range([marginLeft, width - marginRight])
            .padding(0.4); // Ajustez ce padding pour modifier la largeur des chandeliers

          // Ajuster l'échelle Y et inverser la plage
          const y = d3.scaleLinear()
            .domain([d3.min(dataSubset, d => d.low), d3.max(dataSubset, d => d.high)]) // Assurez-vous que low est en bas et high en haut
            .nice() // Ajoute un léger espace pour que les valeurs s'ajustent mieux
            .range([height - marginBottom, marginTop]); // Inverser la plage pour que le haut soit à la bonne position

          // Ajouter l'axe X
          svg
            .append("g")
            .attr("transform", `translate(0, ${height - marginBottom})`)
            .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b %d")).ticks(width / 80).tickSizeOuter(0));

          // Ajouter l'axe Y
          svg
            .append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(d3.axisLeft(y).ticks(height / 40));

          // Ajouter les chandeliers
          svg.selectAll("g.candle")
            .data(dataSubset)
            .enter()
            .append("g")
            .attr("class", "candle")
            .attr("transform", d => `translate(${x(d.date)},0)`)
            .each(function(d) {
              console.log("Chandelier data:", d);

              const g = d3.select(this);

              // Ajouter la mèche (ligne entre low et high)
              g.append("line")
                .attr("y1", y(d.low))
                .attr("y2", y(d.high))
                .attr("x1", x.bandwidth() / 2)
                .attr("x2", x.bandwidth() / 2)
                .attr("stroke", "black");

              if (!isNaN(y(d.open)) && !isNaN(y(d.close))) {
                // Ajouter le corps du chandelier (rectangle pour open à close)
                g.append("rect")
                  .attr("y", y(Math.max(d.open, d.close)))
                  .attr("height", Math.abs(y(d.open) - y(d.close)))  // Assurez-vous que la hauteur est toujours positive
                  .attr("width", x.bandwidth())
                  .attr("fill", d.open > d.close ? "#ff0000" : "#00ff00")
                  .attr("stroke", "black");
              } else {
                console.log("Données incorrectes pour le chandelier:", d);
              }
            });
        } else {
          console.log("Aucune donnée disponible dans Firebase.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, []);

  return <svg ref={chartRef} />;
};

export default Chart3;
