import React from "react";

const Youtube2 = () => {
  return (
    <div className="flex justify-center aspect-video flex-col items-center" data-aos="fade-up">
        <iframe
          src="https://www.youtube.com/embed/8EJz-H54l5Q?si=ARZsHviVoMttPubg"
          title="YouTube video"
          allowFullScreen
          className="h-[250px] sm:h-[700px] w-full rounded-lg"
        ></iframe>
    </div>
  );
};

export default Youtube2;
